import {Button, Form, Modal, Step} from "semantic-ui-react";
import React, {useState} from "react";
import {VARIABLE_NAMES} from "../../constants";
import moment from "moment";

const GenerateRates = ({providerOptions} : any) => {
    const [isAutoGenerateOpen, setIsAutoGenerateOpen] = useState(false);

    const [selectedStep, setSelectedStep] = useState(0); // Keeps track of the current step
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [selectedVariables, setSelectedVariables] = useState([]);
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [selectedYear, setSelectedYear] = useState([]);
    const [defaultValues, setDefaultValues] = useState({});






    // TODO pull variableOptions from constants.ts file from VARIABLE_NAMES and store then in a varibale called variableOptions

    const variableOptions = VARIABLE_NAMES
        .map(({ key, value }) => ({
            key,
            value,
            text: value,
        }));

    //TODO - Pull monthOptions and yearOptions from constants.ts file
    const monthOptions = [
        { key: "January", value: "January", text: "January" },
        { key: "February", value: "February", text: "February" },
        { key: "March", value: "March", text: "March" },
        { key: "April", value: "April", text: "April" },
        { key: "May", value: "May", text: "May" },
        { key: "June", value: "June", text: "June" },
        { key: "July", value: "July", text: "July" },
        { key: "August", value: "August", text: "August" },
        { key: "September", value: "September", text: "September" },
        { key: "October", value: "October", text: "October" },
        { key: "November", value: "November", text: "November" },
        { key: "December", value: "December", text: "December" },
    ];


    const yearOptions = [
        { key: "2023", value: "2023", text: "2023" },
        { key: "2024", value: "2024", text: "2024" },
    ];

    const handleInputChange = (e: any, { name, value }:any) => {
        setDefaultValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };


    // @ts-ignore
    const steps = [
        {
            title: "Select Providers",
            content: (
                <Form>
                    <Form.Dropdown
                        label="Select Providers"
                        placeholder="Select Providers"
                        fluid={true}
                        multiple={true}
                        search={true}
                        selection={true}
                        options={providerOptions}
                        value={selectedProviders}
                        onChange={(e, { value }) => setSelectedProviders(value)}
                    />
                </Form>
            ),
        },
        {
            title: "Select Variables",
            content: (
                <Form>
                    <Form.Dropdown
                        label="Select Variables"
                        placeholder="Select Variables"
                        fluid={true}
                        multiple={true}
                        search={true}
                        selection={true}
                        options={variableOptions}
                        value={selectedVariables}
                        onChange={(e, { value }) => setSelectedVariables(value)}
                    />
                </Form>
            ),
        },
        {
            title: "Select Month & Year",
            content: (
                <Form>
                    <Form.Dropdown
                        label="Select Months"
                        placeholder="Select Months"
                        fluid={true}
                        multiple={true}
                        search={true}
                        selection={true}
                        options={monthOptions}
                        value={selectedMonths}
                        onChange={(e, { value }) => setSelectedMonths(value)}
                    />
                    <Form.Dropdown
                        label="Select Year"
                        placeholder="Select Year"
                        fluid={true}
                        search={true}
                        selection={true}
                        multiple={true}
                        options={yearOptions}
                        value={selectedYear}
                        onChange={(e, { value }) => setSelectedYear(value)}
                    />
                </Form>
            ),
        },
        {
            title: "Default Values",
            content: (
                <Form>
                    {selectedVariables.map((variable) => (
                        <Form.Input
                            key={variable}
                            label={`Default value for ${variable}`}
                            name={variable}
                            value={defaultValues[variable] || ""}
                            onChange={handleInputChange}
                        />
                    ))}
                </Form>
            ),
        },
        {
            title: "Summary",
            content: (
                <div>
                    <h3>Summary of Selections</h3>
                    <ul>
                        <li>Providers: {selectedProviders.join(", ")}</li>
                        <li>Variables: {selectedVariables.join(", ")}</li>
                        <li>Months: {selectedMonths.join(", ")}</li>
                        <li>Year: {selectedYear}</li>
                        <li>
                            Default Values:
                            <ul>
                                {selectedVariables.map((variable) => (
                                    <li key={variable}>
                                        {variable}: {defaultValues[variable] || "Not set"}
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </div>
            ),
        },
    ];





    // Step 4: Default values for selected variables


    // Step 5: Handle Auto Generate
    const handleAutoGenerate = () => {
        // Generate the data based on the selections
        console.log("Selected Providers:", selectedProviders);
        console.log("Selected Variables:", selectedVariables);
        console.log("Selected Months:", selectedMonths);
        console.log("Selected Year:", selectedYear);

        const newDefaultValues = selectedProviders.flatMap((provider) =>
            selectedVariables.flatMap((variableDefination:object) =>
                selectedYear.flatMap((year) =>
                    selectedMonths.map((month) => ({
                        provider_id: provider?.id ?? null,
                        variable_name: variableDefination?.value ?? '',
                        variable_value: defaultValues[variableDefination?.value] ?? null,
                        effective_from: moment(`${year}-${month}-01`).format('YYYY-MM-DD'),
                        effective_to: moment(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD'),
                        is_active: true
                    }))
                )
            )
        );

        console.log("Saved Data:", newDefaultValues); // Debugging output



        //TODO - Add API call to send data to backend

        setIsAutoGenerateOpen(false); // Close the modal after generation
    };



    return (
        <div>
            <Button labelPosition='right' color='teal' content='Auto Generate Values' icon='shuffle' labelPosition='left' onClick={() => setIsAutoGenerateOpen(true)} />
            {/* Modal with Stepper */}
            <Modal open={isAutoGenerateOpen} onClose={() => setIsAutoGenerateOpen(false)} closeIcon={true}>
                <Modal.Header>Auto Generate Values</Modal.Header>
                <Modal.Content>
                    <Step.Group fluid={true}>
                        {steps.map((step, index) => (
                            <Step
                                key={index}
                                active={selectedStep === index}
                                completed={selectedStep > index}
                                onClick={() => setSelectedStep(index)}
                            >
                                <Step.Content>
                                    <Step.Title>{step.title}</Step.Title>
                                </Step.Content>
                            </Step>
                        ))}
                    </Step.Group>

                    <div style={{ marginTop: "20px" }}>
                        {steps[selectedStep].content}
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    {selectedStep > 0 && (
                        <Button onClick={() => setSelectedStep(selectedStep - 1)}>
                            Previous
                        </Button>
                    )}
                    {selectedStep < steps.length - 1 ? (
                        <Button
                            primary={true}
                            onClick={() => setSelectedStep(selectedStep + 1)}
                        >
                            Next
                        </Button>
                    ) : (
                        <Button primary={true} onClick={handleAutoGenerate}>
                            Generate
                        </Button>
                    )}
                    <Button onClick={() => setIsAutoGenerateOpen(false)}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
}

export default GenerateRates;