import React from 'react';

import {RootState} from "../model";
import {connect} from "react-redux";
import {Button, Icon, Modal} from "semantic-ui-react";
import DynamicHtmlTable from "./DynamicHtmlTable";
import moment from "moment";
import {cantFindMemberText} from "../constants";


function ErrorReportModel(props: any) {

  const getTableHeading = () => {
    let TableHead = new Array();
    TableHead[0] = {title: 'Practice'};
    TableHead[1] = {title: 'PatNum'};
    TableHead[2] = {title: 'View File'};
    TableHead[3] = {title: 'Status'};
    TableHead[4] = {title: 'Date'};
    TableHead[5] = {title: 'Insurance'};
    TableHead[6] = {title: 'Description'};
    TableHead[7] = {title: 'Status'};
    TableHead[8] = {title: 'View'};
    return TableHead;
  }
  
  const getPDFfiles = (carrier: any, row: any, appDate: any, location: string) => {
    const carrierName = carrier.modifyCarrierName;
    let obj:any = {
      carrierName : carrierName,
      subscriberID : row.SubscriberID.trim(),
      date : appDate,
      patNum : row.PatNum,
      location: location
    }
    props.showPDFFile(obj)
  }
  
  const getTableData = () => {
    const patientData = props.patientData;
    const carrierList = props.patients.carrierIdentity;
    let TableData = new Array();
    patientData.forEach((row: any) => {
      let data = new Array();
      const mdhmModalObj = {
        type: row.type,
        show: true,
        patNum: row.patNum,
        practice : row.practice,
        date: row.date
      }
      const appDate = moment(row.Apt_Date,'MM-DD-YYYY').format('YYYY-MM-DD');
      const carrierName = row.insurance ? row.insurance.toLowerCase() : '';
      const isCarrierExist = carrierList.find((carrier: any) => carrier.carrierName.toLowerCase() === carrierName
        || carrier.modifyCarrierName.toLowerCase() === carrierName);
      const getPDF = isCarrierExist ? (row.status !== cantFindMemberText ?
        <a onClick={() => getPDFfiles(isCarrierExist, row, appDate, row.practice)}>View
          Files</a> : 'No Image') : '';
      const view = <a onClick={() => props.showMDHM(mdhmModalObj)}>View</a>
      let status = <Icon name='question circle' color='yellow'/>
      if (row.patientStatus === 'PT Assigned To Practice Active Insurance') {
        status = <Icon name='check circle' color='green'/>
      } else if (row.patientStatus === 'PT Does NOT Have Active Insurance') {
        status = <Icon name='delete' color='red'/>
      } else if (row.patientStatus === 'PT Has Active Insurance But Not Assigned To Practice') {
        status = <Icon name='font' color='red'/>
      }
      data.push([
        {title: row.practice},
        {title: row.patNum},
        {title: getPDF},
        {title: status},
        {title: row.date},
        {title: row.insurance},
        {title: row.description},
        {title: row.status ? 'Resolved' : 'Open'},
        {title: view}
      ])
      TableData = TableData.concat(data)
    });
    return (TableData)
  }
  
  return (
      <Modal
        open={true}
        onClose={() => props.handleClose({ show: false})}
        aria-labelledby="ModalHeader"
        centered={true}
        closeIcon={true}
        size={'large'}
      >
        
        <Modal.Header>Error Report</Modal.Header>
        <Modal.Content scrolling={true}>
          <div className="table-adjAcknowledgement">
            <DynamicHtmlTable
              key={0}
              className={'adjustment'}
              tableHead={getTableHeading()}
              heading={""}
              tableData={getTableData()}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="OK"
            primary={true}
          />
        </Modal.Actions>
        
      </Modal>
   
  )
}

function mapStateToProps(state: RootState) {
  return {
    practice: state.practice,
    patients: state.patients,
  };
}

export default connect(mapStateToProps)(ErrorReportModel);