import React from "react";
import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import NumberInput from "./NumberInput";


interface props {
    type: string,
    placeholder: string,
    name: string,
    value: string,
    onChange: any,
    options?: { name: string, id: number, value: any }[]
}

const DynamicFieldController = ({ type, ...props }) => {

    switch (type) {
        case "text":
            return <TextInput {...props} />;
        case "number":
            return <NumberInput {...props} />;
        case "select":
            return <SelectInput {...props} />;
        default:
            return <TextInput {...props} />;
    }
}
export default DynamicFieldController;