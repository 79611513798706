import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Button, Dropdown, Form, Input, Modal, Table,} from "semantic-ui-react";
import MonthYearSelector from "./MonthYearSelector";

import {VARIABLE_NAMES} from "../../constants";
import *  as doctorVariableActions from "../../redux/actions/doctorVariables"
import * as providerActions from "../../redux/actions/provider";
import ProviderRatesAddForm from "./ProviderRatesAddForm";
import GenerateRates from "./GenerateRates";



function getProviderNameById(data: any[], id: any) {
    console.log(id,'data',data)
    //convert id to same type when comparing
    id = parseInt(id);
    const provider = data.find((item) => item.value === id);
    return provider ? provider.text : 'Not found'; // Return the name or null if not found
}


const getManipulateData  = (data: any):Dictionary<unknown[]> => {

    return data.reduce((acc, item) => {
        const { doctor_id, variable_name, variable_value } = item;

        if (!acc[doctor_id]) {
            acc[doctor_id] = {};
        }


        if (!acc[doctor_id][variable_name]) {
            acc[doctor_id][variable_name] = {
                value: variable_value, // Set the first item's variable_value
                data: [],             // Initialize an empty array for data
            };
        }

        // Push the current item into the data array
        acc[doctor_id][variable_name].data.push(item);

        return acc;
    }, {});
}

const ProviderRates = () => {
    const [selectedYear, setSelectedYear] = useState("");
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth(); // 0-based (January is 0)
    const [selectedMonthIndex, setSelectedMonthIndex] = useState(currentMonthIndex);
    const [providers, setProviders] = useState([]);
    const [pageData, setPageData] = useState([])
    const [providerOptions,setProviderOptions ] = useState([])
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(doctorVariableActions.fetchDoctorVariables())
            .then((res: any)=>setPageData(res))
    }, []);

    useEffect(() => {
        dispatch(providerActions.getProviderListOptions())
            .then((res: any)=>setProviderOptions(res))

    }, []);



    // Modal state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentProvider, setCurrentProvider] = useState(null);

    // Handle MonthYearSelector change
    const handleMonthYearChange = (monthIndex, year) => {
        setSelectedMonthIndex(monthIndex);
        setSelectedYear(year);
    };

    // Handle Add/Edit Modal open
    const handleModalOpen = (provider = null) => {
        setCurrentProvider(provider);
        setIsModalOpen(true);
    };

    // Handle Modal close
    const handleModalClose = () => {
        setCurrentProvider(null);
        setIsModalOpen(false);
    };

    // Add or Update provider
    const handleSaveProvider = async () => {
        const updatedProviders = [...providers];
        if (currentProvider.id) {
            // Update existing provider
            const index = updatedProviders.findIndex(
                (p) => p.id === currentProvider.id
            );
            updatedProviders[index] = currentProvider;
        } else {
            // Add new provider
            currentProvider.id = Date.now(); // Mock ID generation
            updatedProviders.push(currentProvider);
        }

        dispatch(doctorVariableActions.saveDoctorVariable(currentProvider));
        handleModalClose();
    };

    // Delete provider
    const handleDeleteProvider = async (id:any) : Promise<void> => {
        const updatedProviders = providers.filter((provider) => provider.id !== id);
        //TODO: Implement delete API
    };
    const manipulateData = getManipulateData(pageData)

    return (
        <div>
            <Button basic={true} color="green" onClick={() => handleModalOpen()}>
                Add Provider
            </Button>
            <GenerateRates providerOptions={providerOptions} />
            <Table className="ui table table-striped table-hover celled unstackable no-wrap">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Provider Name</Table.HeaderCell>
                        {VARIABLE_NAMES?.map(({key,value}) => (
                            <Table.HeaderCell key={key}>{value}</Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {Object.keys(manipulateData).map((doctor_id) => {
                        return(
                            <Table.Row key={doctor_id}>
                                <Table.Cell>{getProviderNameById(providerOptions,doctor_id)}</Table.Cell>
                                {VARIABLE_NAMES?.map(({key,value}) => {
                                    return <Table.Cell key={key}>{manipulateData[doctor_id][value]?.value ?? '--'}</Table.Cell>
                                })
                                }
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>

            {/* Modal for Add/Edit */}
            <ProviderRatesAddForm
                isModalOpen={isModalOpen}
                handleModalClose={handleModalClose}
                currentProvider={currentProvider}
                setCurrentProvider={setCurrentProvider}
                providerOptions={providerOptions}
                handleSaveProvider={handleSaveProvider}
            />
        </div>
    );
};

export default ProviderRates;
