import {Button, Dropdown, Form, Input, Modal} from "semantic-ui-react";
import {VARIABLE_NAMES} from "../../constants";
import React from "react";
import CacheBuster from "react-cache-buster";
// @ts-ignore
import propTypes = CacheBuster.propTypes;
import {boolean} from "yup";
import DynamicFieldController from "../DynamicFormInputs/DynamicFieldController";

const humaniseText = (text: string) => {
    return text.replace(/([A-Z])/g, ' $1')
        .replace(/^./, function (str) {
            return str.toUpperCase();
        })
}


interface props {
    isModalOpen: boolean,
    handleModalClose: ()=>void,
    currentProvider: any,
    setCurrentProvider: any,
    providerOptions: any,
    handleSaveProvider: any,
}

const ProviderRatesAddForm = ({isModalOpen, handleModalClose, currentProvider, setCurrentProvider,providerOptions,handleSaveProvider}:props) =>{
    return(
        <Modal open={isModalOpen} onClose={handleModalClose}>
            <Modal.Header>
                {currentProvider?.id ? "Edit Provider" : "Add Provider"}
            </Modal.Header>
            <Modal.Content style={{ maxHeight: "60vh", overflowY: "auto" }}>
                <Form>
                    { !currentProvider?.id && <Form.Field key={'x1'}>
                        <label>{'Select Provider'}</label>
                        <Dropdown
                            placeholder='Select Provider'
                            fluid={true}
                            search={true}
                            selection={true}
                            onChange={(e, { value }) => {
                                setCurrentProvider({
                                    ...currentProvider,
                                    doctor_id: value,
                                })
                            }
                            }
                            options={providerOptions}
                        />
                    </Form.Field>}
                    {VARIABLE_NAMES?.map(({key,value, inputType,options}) => {
                        return <Form.Field key={key}>
                            <label>{humaniseText(value)}</label>
                            <DynamicFieldController
                                type={inputType}
                                placeholder={value}
                                name={value}
                                options={options}
                                value={currentProvider?.[value] || ""}
                                onChange={(e:any) =>
                                    setCurrentProvider({
                                        ...currentProvider,
                                        [value]: e.target.value,
                                    })
                                }
                            />
                        </Form.Field>

                    })}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="red" onClick={handleModalClose}>
                    Cancel
                </Button>
                <Button color="green" onClick={handleSaveProvider}>
                    Save
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ProviderRatesAddForm;