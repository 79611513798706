//generate a text input component

import React from "react";

interface props {
    name: string,
    value: string,
    onChange: any,
    placeholder: string,
}

const TextInput = ({ name, value, onChange, placeholder }:props) => {
    return (
        <input
            type="text"
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
        />
    );
}

export default TextInput;
