import React from "react";
import ProviderRates from "./ProviderRates";

const ProviderRatesTabs = () => (
    <div>
        <div className="ui card">
            <div className="content">
                <div className="ui card">
                    <div className="content">
                        <div className="FreezeTable">
                            <ProviderRates/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
export default ProviderRatesTabs;
