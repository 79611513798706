// generate a number input component
import React from "react";

interface props {
    name: string,
    value: number,
    onChange: any,
    placeholder: string,
}
const NumberInput = ({ name, value, onChange, placeholder }:props) => {
    return (
        <input
            type="number"
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
        />
    );
}

export default NumberInput;