import React from "react";
import Template from "../components/Template";
import ProviderRatesTabs from "../components/ProviderRates/ProviderRatesTabs";

function ProviderRatesTracker() {

    return (
        <Template activeLink='providerRatesTracker'>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">Provider Rates Tracker</h2>
                </div>
            </div>
            <ProviderRatesTabs/>
        </Template>
);

}

export default ProviderRatesTracker;