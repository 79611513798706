import {MainMenu} from "./constants";

export const menuAdminArray: Array<MainMenu> = [
    {
        pageId: 57,
        name: "users",
        class: "innerDropdown",
        icon: "users",
        title: "Users",
        partner: 'Users',
        role: [],
    },
    {
        pageId: 58,
        name: "provider-profiles",
        class: "innerDropdown",
        icon: "vcard",
        title: "Provider Profiles",
        partner: 'provider-profiles',
        role: [],
    }, {
        pageId: 20,
        name: 'xray-service-dashboard',
        class: 'innerDropdown',
        icon: 'dashboard',
        title: 'Xray Service Dashboard',
        partner: 'xray-service-dashboard',
        role: []
    },
    {
        pageId: 21,
        name: 'user-matrix-report',
        class: 'innerDropdown',
        icon: 'clipboard list',
        title: 'User Matrix',
        partner: 'user-matrix-report',
        role: [],
    },
    {
        name: "mailing-settings",
        class: "innerDropdown",
        icon: "cogs",
        title: "Mailing",
        partner: 'mailing-settings',
        role: [],
        subMenus: [
            {
                name: "crons-mailing-settings",
                icon: 'mail outline',
                title: "Crons Mailing Settings",
                role: []
            }
        ]
    },
    {
        name: "manual-uploads",
        class: "innerDropdown",
        icon: "upload",
        title: "Manual Uploads",
        partner: 'manual-uploads',
        role: [],
        subMenus: [
            {
                name: "pt-count-care",
                icon: 'upload',
                title: "Patient Count and Care",
                role: []
            }
        ]
    },
    {
        pageId: 59,
        name: "goal",
        class: "innerDropdown",
        icon: "target",
        title: "Goal",
        partner: 'goal',
        role: [],
        subMenus: [
            {
                pageId: 58,
                name: "PCR Goal",
                icon: 'target',
                title: "PCR Goal",
                role: []
            }, {
                pageId: 59,
                name: "Oral Surgery Goal",
                icon: 'target',
                title: "Oral Surgery Goal",
                role: []
            }
        ]
    },
    {
        pageId: 62,
        name: "SOFD",
        class: "innerDropdown",
        icon: "list",
        title: "SOFD",
        partner: 'SOFD',
        role: [],
        subMenus: [
            {
                pageId: 87,
                name: "Re Run PCR",
                icon: 'setting',
                title: "Re Run PCR",
                role: ['admin']
            },
            {
                pageId: 63,
                name: "MCO Login Report",
                icon: 'setting',
                title: "MCO Login Report",
                role: [],
                grandSubMenu: [
                    {
                        pageId: 93,
                        name: "Logins",
                        icon: 'setting',
                        title: "Logins",
                        role: []
                    }
                ]
            },
            {
                pageId: 64,
                name: "Available Api List",
                icon: "setting",
                title: "Available Api List",
                role: [],
            },
            {
                pageId: 105,
                name: "Five9 List",
                icon: "setting",
                title: "Five9 List",
                role: [],
            },
            {
                pageId: 106,
                name: "MDM",
                icon: "setting",
                title: "MDM",
                role: [],
            },
            
            {
                pageId: 107,
                name: "Adjustment",
                icon: "setting",
                title: "Adjustment",
                role: [],
            },
            {
                pageId: 65,
                name: "Crons",
                icon: "setting",
                title: "Crons",
                role: ['admin'],
            },
            {
                pageId: 65,
                name: "Lambdas",
                icon: "amazon",
                title: "Lambdas",
                role: ['admin'],
            },
            {
                pageId: 66,
                name: "DCRM Automation Status",
                icon: "setting",
                title: "DCRM Automation Status",
                role: ['admin'],
            },
            {
                pageId: 82,
                name: "MCO Scrapper",
                icon: 'setting',
                title: "MCO Scrapper",
                role: []
            },
            {

                pageId: 83,
                name: "Adjustment List",
                icon: 'setting',
                title: "Adjustment List",
                role: []
            }, {
                pageId: 85,
                name: "Write Off list",
                icon: 'setting',
                title: "Write Off list",
                role: []
            },
            {
                pageId: 86,
                name: "Email Configurations",
                icon: 'mail',
                title: "Email Configurations",
                role: []
            },
            {
                pageId: 87,
                name: "App Status Tracker",
                icon: 'setting',
                title: "App Status Tracker",
                role: []
            },
            {
                pageId: 169,
                name: "Application Version Tracking",
                icon: 'setting',
                title: "Application Version Tracking",
                role: []
            },
            {
                pageId: 88,
                name: "mfa",
                icon: 'setting',
                title: "MFA",
                role: [],
                grandSubMenu: [
                    {
                        pageId: 91,
                        name: "dentalhub",
                        icon: 'setting',
                        title: "UHC",
                        role: []
                    },
                    {
                        pageId: 92,
                        name: "mcna",
                        icon: 'setting',
                        title: "MCNA",
                        role: []
                    }
                ]


            },
        ]
    },
    {
        pageId: 67,
        name: "Setup",
        class: "innerDropdown",
        icon: "setting",
        title: "Setup",
        partner: 'Setup',
        role: [],
        subMenus: [{
            pageId: 68,
            name: "Practice Admin List",
            icon: 'setting',
            title: "Practice",
            role: ['admin'],
        },
            {
                pageId: 69,
                name: "Users",
                icon: "setting",
                title: "Users",
                role: [],
            },
            {
                pageId: 70,
                name: "Regions",
                icon: "setting",
                title: "Regions",
                role: ['admin'],
            },
            {
                pageId: 94,
                name: "Districts",
                icon: "setting",
                title: "Districts",
                role: ['admin'],
            },
            {
                name: "Carrier Identity",
                redirectUrl: "https://dcrm.cdp.dental/carrier-identity",
                icon: "setting",
                title: "Carrier Identity",
                role: ['admin'],
            },
            {
                pageId: 80,
                name: "Procedure Code Change",
                icon: "setting",
                title: "Procedure Code Change",
                role: ['admin'],
            },
            {
                pageId: 89,
                name: "GOX Procedure Code List",
                icon: "setting",
                title: "GOX Procedure Code",
                role: ['admin'],
            },
            {
                pageId: 90,
                name: "GOZ Procedure Code List",
                icon: "setting",
                title: "GOZ Procedure Code",
                role: ['admin'],
            },
            {
                pageId: 72,
                name: "Manager Help",
                icon: "help",
                title: "Manager Help",
                role: ['admin'],
            },
            {
                pageId: 73,
                name: "Leap Edit",
                icon: "setting",
                title: "Leap Edit",
                role: ['admin'],
            },
            {
                pageId: 74,
                name: "Spot Commlog Search",
                icon: "setting",
                title: "Spot Commlog Search",
                role: ['admin'],
            },
            {
                pageId: 77,
                name: "Notification Emails",
                icon: "setting",
                title: "Notification Emails",
                role: ['admin'],
            },
            {
                pageId: 78,
                name: "Brands",
                icon: "setting",
                title: "Brands",
                role: ['admin'],
            },
            {
                pageId: 79,
                name: "Educator Notifications",
                icon: 'setting',
                title: "Educator Notifications",
                role: ['admin'],
            },
            {
                pageId: 81,
                name: "Ramp Threshold Defaults",
                icon: 'setting',
                title: "Ramp Threshold Defaults",
                role: ['admin'],
            },
            {
                pageId: 109,
                name: "Provider Rates Tracker",
                icon: 'settings',
                title: "Provider Rates Tracker",
                role: []
            },
            {
                pageId: 82,
                name: "Hygienist Bracket System",
                icon: 'setting',
                title: "Hygienist Bracket System",
                role: ['admin'],
            },
            {
                pageId: 83,
                name: "Dr App Permission Matrix",
                icon: 'setting',
                title: "Dr App Permission Matrix",
                role: ['admin'],
            },
            {
                pageId: 84,
                name: "Sage Accounts Description",
                icon: 'settings',
                title: "Sage UKG Accounts",
                role: []
            },
            {
                pageId: 85,
                name: "Implant Cost",
                icon: 'settings',
                title: "Implant Cost",
                role: []
            },
            {
                pageId: 88,
                name: "Provider Earnings Report",
                icon: 'settings',
                title: "Provider Earnings Report",
                role: []
            },
            {
                pageId: 89,
                name: "Financial Note Category",
                icon: 'settings',
                title: "Financial Note Category",
                role: []
            },
            {
                pageId: 90,
                name: "Ortho Provider Earnings Report",
                icon: 'settings',
                title: "Ortho Provider Earnings Report",
                role: []
            },
            {
                pageId: 91,
                name: "TED Collections",
                icon: 'settings',
                title: "TED Collections",
                role: []
            }
        ]
    },
    {
        pageId: 84,
        name: 'Usage',
        class: 'innerDropdown',
        icon: 'pie chart',
        title: 'Usage',
        partner: 'Usage',
        role: [],
        subMenus: [
            {
                pageId: 92,
                name: "Universal Usage Report",
                icon: 'pie chart',
                title: "Universal Usage Report",
                role: []
            },
            {
                pageId: 93,
                name: "Employee Usage Report",
                icon: 'pie chart',
                title: "Employee Usage Report",
                role: []
            },
            {
                pageId: 101,
                name: "Non Employee Usage Report",
                icon: 'pie chart',
                title: "Non Employee Usage Report",
                role: []
            },
            {
                pageId: 102,
                name: "OD APIs Usage Report",
                icon: 'pie chart',
                title: "OD APIs Usage Report",
                role: []
            },
        ]
    },
    {
        pageId: 95,
        name: "ukg",
        class: "innerDropdown",
        icon: "list",
        title: "UKG",
        partner: 'UKG',
        role: ['ukg'],
        subMenus: [{
            pageId: 96,
            name: "monthly",
            icon: 'dollar',
            title: "Monthly Pay",
            role: ['ukg'],
        },
            {
                pageId: 97,
                name: "cumulative",
                icon: "dollar",
                title: "Cumulative Pay",
                role: ['ukg'],
            }]
    },
];
