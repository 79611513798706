import React, { useEffect, useState } from 'react';
import { useActions } from '../../../redux/actions';
import * as HealthStatusActions from "../../../redux/actions/healthStatus";
import { AccordionTitle, AccordionContent, Icon, Grid, GridRow, GridColumn, Loader, Input } from 'semantic-ui-react'
import ProgressBar from '../ProgressBar';
import moment from 'moment';
import AwsBounceTable from "./AwsBounceTable";


function AwsBounceEmails() {
    const initialState = { panel: '', loading: false, data: [], folder: moment().format('YYYY-MM-DD'), counts: { failed: 0, success: 0, pending: 0 } };
    const [state, setState] = useState(initialState);
    const healthStatusActions = useActions(HealthStatusActions);
    const { panel, loading, counts, folder } = state;
    const prefix = 'bounce-emails';

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }

    const getBounceEmails = (date: string) => {
        setState({ ...state, loading: true })
        healthStatusActions.get(`health-status/bounce-emails?date=${moment(date).format('YYYY-M-DD')}`).then((response: any) => {
            const data = response.map((no: any) => (JSON.parse(no)))
            setState({ ...state, folder: date, data, loading: false });
        })
    }

    useEffect(() => {
        setState({ ...state, loading: true })
        const date = moment().format('YYYY-MM-DD');
        getBounceEmails(date);
    }, [])


    return (
        <>
            <AccordionTitle active={panel === prefix} onClick={() => onSelect(prefix)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>AWS Bounce Emails</span>
                            {state.loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar failed={counts.failed} pending={counts.pending} success={counts.success} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === prefix}>
                {!loading && <Grid>
                    <GridRow>
                        <GridColumn width={16}>
                            <Input type='date' format ="YYYY-M-DD" value={folder} onChange={(e, data) => getBounceEmails(data.value)} />
                            {state.data && <AwsBounceTable records={state.data} />}
                        </GridColumn>
                    </GridRow>
                </Grid>}

            </AccordionContent>
        </>
    );
}

export default AwsBounceEmails;